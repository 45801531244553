<template>
  <div>
    <b-card>
      <b-card-body>
        <b-form novalidate @submit.prevent="handleSubmit">
          <b-form-group label="Senha atual" label-for="currentPassword">
            <b-form-input
              id="currentPassword"
              v-model="form.currentPassword"
              type="password"
              required
              :state="validation.currentPassword"
            />
            <b-form-invalid-feedback v-if="$v.form.currentPassword.$invalid">
              Informe a sua senha atual
            </b-form-invalid-feedback>
          </b-form-group>
          <b-form-group label="Nova Senha" label-for="password">
            <b-form-input
              id="password"
              v-model="form.password"
              type="password"
              required
              :state="validation.password"
            />
            <b-form-invalid-feedback v-if="$v.form.password.$invalid">
              Informe uma nova senha
            </b-form-invalid-feedback>
          </b-form-group>
          <b-form-group
            label="Confirmar nova senha"
            label-for="passwordConfirmation"
          >
            <b-form-input
              id="passwordConfirmation"
              v-model="form.passwordConfirmation"
              type="password"
              required
              :state="validation.passwordConfirmation"
            />
            <b-form-invalid-feedback
              v-if="$v.form.passwordConfirmation.$invalid"
            >
              <span
                v-if="!$v.form.passwordConfirmation.required"
                class="d-block"
              >
                Informe novamente a senha
              </span>
              <span
                v-else-if="!$v.form.passwordConfirmation.matchesPassword"
                class="d-block"
              >
                As senhas devem ser iguais
              </span>
            </b-form-invalid-feedback>
          </b-form-group>
        </b-form>
      </b-card-body>
    </b-card>

    <div class="mt-3 d-flex gap-1 justify-content-end">
      <b-button variant="outline-secondary" @click="handleCancel('Home')">
        Cancelar
      </b-button>
      <b-button variant="success" @click="handleSubmit">
        Alterar Senha
      </b-button>
    </div>
  </div>
</template>

<script>
import withFormValidation from '@/mixins/withFormValidation'
import { required } from 'vuelidate/lib/validators'

export default {
  name: 'ResetPasswordForm',
  mixins: [
    withFormValidation({
      fields: ['currentPassword', 'password', 'passwordConfirmation']
    })
  ],
  data() {
    return {
      form: {
        email: ''
      }
    }
  },
  validations: {
    form: {
      currentPassword: { required },
      password: { required },
      passwordConfirmation: {
        required,
        matchesPassword: (value, { password }) => value === password
      }
    }
  },
  watch: {
    form(value) {
      this.$emit('input', value)
    }
  }
}
</script>
