<template>
  <Layout>
    <b-breadcrumb :items="breadcrumbs" />
    <ChangePasswordForm v-model="credentials" @submit="onSubmit" />
  </Layout>
</template>

<script>
import Layout from '../Layout'
import ChangePasswordForm from '@/components/Auth/ChangePasswordForm'

export default {
  name: 'ChangePassword',
  components: {
    Layout,
    ChangePasswordForm
  },
  data() {
    return {
      credentials: {
        currentPassword: '',
        password: '',
        passwordConfirmation: ''
      }
    }
  },
  computed: {
    breadcrumbs: () => [
      {
        text: 'Início',
        to: { name: 'HomeIndex' }
      },
      {
        text: 'Alterar Senha',
        to: { name: 'ChangePassword' },
        active: true
      }
    ]
  },
  methods: {
    onSubmit() {
      console.log('ToDo')
    }
  }
}
</script>
